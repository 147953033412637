import React from "react"
import { useTranslation } from "react-i18next"

import image from "../../images/ebrands.jpg"

const About = () => {
  const { t } = useTranslation("home")
  return (
    <section className="about-section" id="about">
      <div className="image">
        <div className="blue-rect" />
        <img src={image} alt=""/>
      </div>
      <div className="info">
        <h2>{t("about-title")}</h2>
        <p>
          {t("about-p1")}
        </p>
        <p>
          {t("about-p2")}
        </p>
        <p>
          {t("about-p3")}
        </p>
      </div>
    </section>
  )
}

export default About;