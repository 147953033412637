import React from "react"

import contact from "../../images/contact2.png"
import triangle from "../../images/triangle.png"
import { useTranslation } from "react-i18next"

const Contact = () => {
  const { t } = useTranslation("home")
  return (
    <section className="contact-section" id="contact">
      <img src={triangle} className="triangle" alt=""/>
      <div className="content">
        <div className="info">
          <h2>{t("contact-title")}</h2>
          <p>
            {t("contact-text1")} <a href={`mailto:${t("contact-email")}`} className="link">{t("contact-email")}</a>
          </p>
          <div className="left">
            <a href={`mailto:${t("contact-email")}`} className="primary-button">{t("contact-button")}</a>
          </div>
          <br/><br/>
          <p>
            Av. Gustavo Baz Prada 47-3 <br/>
            Col. Xocoyahualco, Tlalnepantla. <br/>
            Estado de México
          </p>
        </div>
        <div className="image">
          <img src={contact} alt=""/>
        </div>
      </div>
    </section>
  )
}

export default Contact;