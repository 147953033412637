
import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import { AlternateLinksContext } from "./wrapWithI18nProvider"
import { useTranslation } from "react-i18next"
import { Link as SmoothLink } from "react-scroll";

import logo from "../images/logo.svg"
import phone from "../images/phone.svg"

import icon_mx from "../images/mexico.svg"
import icon_en from "../images/eua.svg"
import icon_de from "../images/germany.svg"

const langArray = [
  {
    name: 'ES',
    icon: require("../images/mexico.svg"),
    code: 'mx'
  },
  {
    name: 'EN',
    icon: require("../images/eua.svg"),
    code: 'en'
  },
  {
    name: 'DE',
    icon: require("../images/germany.svg"),
    code: 'de'
  }
]

const Header = () => {
  const alternateLinks = useContext(AlternateLinksContext)
  const { t, i18n } = useTranslation("home")

  const [isOpen, toggleDropdown] = useState(false);

  const dropdownLanguages = langArray.map((lang) => {
    if(lang.code !== i18n.language) {
      return (
        <Link to={`/${lang.code}`} className="language" key={lang.code}>
          <img src={lang.icon} alt=""/>
          <span>{lang.name}</span>
        </Link>
      )
    }
  });

  return (
    <header>
      <div className="content">
        <div className="logo">
          <Link to={"/" + i18n.language}>
            <img src={logo} alt="Elevate Brands Logo"/>
          </Link>
        </div>
        <div className="links">
          <SmoothLink
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {600}
          >
            {t("header-about")}
          </SmoothLink>
          <SmoothLink
            activeClass="active"
            to="services"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {600}
          >
            {t("header-services")}
          </SmoothLink>
          <SmoothLink
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {1500}
            className="primary-button"
          >
            <img src={phone} alt=""/>
            <div>{t("header-contact")}</div>
          </SmoothLink>
          <div className="languages" onClick={() => toggleDropdown(!isOpen)}>
            <div className="selected">
              {alternateLinks &&
                alternateLinks
                  .filter(link => link.language === i18n.language)
                  .map((link, i) => {
                    if(link.language === 'mx') return <img key={link.language} src={icon_mx} alt=""/>
                    if(link.language === 'en') return <img key={link.language} src={icon_en} alt=""/>
                    if(link.language === 'de') return <img key={link.language} src={icon_de} alt=""/>
                  })
              }
              
            </div>
            <div className="dropdown" style={{display: `${isOpen? 'flex':'none'}`}}>
              {dropdownLanguages}
            </div>
            {/* {alternateLinks &&
              alternateLinks
                .filter(link => link.language === i18n.language)
                .map((link, i) => [
                  i > 0 && " | ",
                  <Link
                    to={link.path}
                    hrefLang={link.language}
                  >
                    {t(link.language)}
                  </Link>,
                ])} */}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
