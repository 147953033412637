import React from "react"
import { Link as SmoothLink } from "react-scroll";
import store from "../../images/store.svg"
import alliance from "../../images/alliance.svg"
import trophy from "../../images/trophy.svg"

import { useTranslation } from "react-i18next"

const Services = () => {
  const { t } = useTranslation("home")
  return (
    <section className="services-section" id="services">
      <div className="content">
        <div className="grid">
          <div className="service">
            <div className="header">
              <div className="icon">
                <img src={trophy} alt=""/>
              </div>
              <div className="title">{t("services-col1-title")}</div>
            </div>
            <p>
              {t("services-col1-text")}
            </p>
          </div>
          <div className="service">
            <div className="header">
              <div className="icon">
                <img src={alliance} alt=""/>
              </div>
              <div className="title">{t("services-col2-title")}</div>
            </div>
            <p>
              {t("services-col2-text")}
            </p>
          </div>
          <div className="service">
            <div className="header">
              <div className="icon">
                <img src={store} alt=""/>
              </div>
              <div className="title">{t("services-col3-title")}</div>
            </div>
            <p>
              {t("services-col3-text")}
            </p>
          </div>
        </div>
      
        <div className="center">
          <SmoothLink
            activeClass="contact"
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {1000}
            className="primary-button"
          >
            {t("services-button")}
          </SmoothLink>
        </div>
      </div>
    </section>
  )
}

export default Services;