import React from "react"

import logo from "../images/logo.svg"

const Footer = () => {

  return (
    <footer>
      <div className="content">
        <div className="logo">
          <img src={logo} alt="Elevate Brands Logo"/>
        </div>
        <div className="copyright">
          Elevate Brands © {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  )
}

export default Footer
