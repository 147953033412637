import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Intro from "../components/index/Intro"
import About from "../components/index/About"
import Services from "../components/index/Services"
import Brands from "../components/index/Brands"
import Contact from "../components/index/Contact"
import Choose from "../components/index/Choose"

const IndexPage = props => {
  return (
    <Layout alternateLink={props.alternateLinks}>
      <SEO title="Bienvenido" />
      <Intro />
      <About />
      <Choose />
      <Services />
      <Brands /> 
      <Contact />
    </Layout>
  )
}

export default IndexPage
