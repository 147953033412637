import React from "react"

import foamie from "../../images/foamie-logo.png"
import { useTranslation } from "react-i18next"

const Brands = () => {
  const { t } = useTranslation("home")
  return (
    <section className="brands-section">
      <div className="content">
        <h2>{t("brands-title")}</h2>
        <div className="brands">
          <div className="brand">
            <div className="image">
              
            </div>
            <div className="info">
              <div className="logo">
                <img src={foamie} alt=""/>
              </div>
              <div className="right">
                <a href="https://foamie.mx" target="_blank" className="primary-button">{t("brands-button")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Brands;