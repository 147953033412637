import React from "react"
import { useTranslation } from "react-i18next"

import choose from "../../images/choose.png"

const Choose = () => {
  const { t } = useTranslation("home")
  return (
    <section className="choose-section" id="choose">
      <div className="content">
        <div className="info">
          <h2>{t("services-title2")}</h2>
          <p>
            {t("services-text2")}
          </p>
          <p>
            <ol>
              <li>{t("services-point1")}</li>
              <li>{t("services-point2")}</li>
              <li>{t("services-point3")}</li>
              <li>{t("services-point4")}</li>
            </ol>
          </p>
          <p>
            {t("services-text4")}
          </p>
        </div>
        <div className="image">
          <img src={choose} alt="Elevate Brands"/>
        </div>
      </div>
    </section>
  )
}

export default Choose;