import React from "react"
import { Link as SmoothLink } from "react-scroll";

import seal from "../../images/seal.svg"
import arrow from "../../images/arrow-down.svg"

import { useTranslation } from "react-i18next"

const Intro = () => {
  const { t } = useTranslation("home")
  return (
    <section className="intro-section">
      <div className="title">
        <img src={seal} alt=""/>
        <h1>{t("intro-title")}</h1>
      </div>
      <p>
        {t("intro-welcome")}
      </p>
      <div className="center">
        <SmoothLink
          activeClass="contact"
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {1500}
          className="primary-button"
        >
          {t("intro-button")}
        </SmoothLink>
      </div>
      <SmoothLink
        activeClass="contact"
        to="about"
        spy={true}
        smooth={true}
        offset={-70}
        duration= {500}
        className="center"
      >
        <img src={arrow} className="arrow" alt=""/>
      </SmoothLink>
    </section>
  )
}

export default Intro;